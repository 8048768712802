// Navigation mobile
function nav_mobile() {
    $('#nav-mobile-trigger').on('click', function () {
        $('body').toggleClass('menu-open');
        $('#nav-main').toggle();
    });
}

// Navigation size
function nav_size() {
    var position = $(window).scrollTop();
    if (position > 220) {
        $('body').addClass('header-small');
    } else {
        $('body').removeClass('header-small');
    }
    $(window).scroll(function () {
        if ($(this).scrollTop() > 220) {
            $('body').addClass('header-small');
        } else {
            $('body').removeClass('header-small');
        }
    });
}

// Navigation sub menu
function nav_sub_menu() {
    var viewport = $(window).width();
    if (viewport < 576) {
        $('#nav-main li[data-navsub] > a').on('click', function (event) {
            $(this).next().toggle();
            return false;
        });
    } else {
        $('#nav-main li[data-navsub] > a').on('click', function (event) {
            var navSub = $(this).parent();
            var isFocus = navSub.hasClass('focus');
            $('#nav-main li[data-navsub]').removeClass('focus');
            if (!isFocus) {
                navSub.addClass('focus');
            }
            event.stopPropagation();
            return false;
        });
        $('html').click(function () {
            $('#nav-main li[data-navsub]').removeClass('focus');
        });
    }
}

// Scroll top button
function scroll_top() {
    $('#btn-back-top').fadeOut(0);
    $(window).scroll(function () {
        if ($(this).scrollTop() > 220) {
            $('#btn-back-top').fadeIn(300);
        } else {
            $('#btn-back-top').fadeOut(300);
        }
    });
    $('#btn-back-top').on('click', function () {
        $('html, body').animate({
            scrollTop: 0
        }, '500');
        return false;
    });
}

function panier_form() {
    let options = $('.checkbox-toggle-round')
    let frm = $('#frm-panier-droite')
    options.on('click', function (e) {
        frm.submit()
    })
}

function service_form() {
    var select = $('#select-room');
    var profile = $('#select-profile');
    var rooms = $('#room-wp');
    var submit = $('#btn-submit');
    var pieces = [];
    var le_slug = rooms.data('slug')
    var pieces_datas = rooms.data('pieces')
    var csrf = $('meta[name="csrf-token"]').attr('content')
    let total = $('.service-form-wp .amount');


    function delay(callback, ms) {
        var timer = 0;
        return function () {
            var context = this, args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                callback.apply(context, args);
            }, ms || 0);
        };
    }

    var addPieces = function (index, datas) {
        if (datas) {
            index = datas.pieces.length
            total.text(datas.total)
        } else {
            console.log(profile.val())
            if (!profile.val())
                $('#error-profile').text('Merci de préciser ce champs')
            total.text(0)
        }
        if (!index)
            return

        rooms.removeClass('active');
        rooms.find('.item.type-room.cloned').remove();
        rooms,
            submit.addClass('active');


        // clone room row
        for (var i = 0; i < index; i++) {
            let input = $('.room-master .item.type-room')
                .clone()
                .addClass('cloned')
                .attr('data-number', i + 1)
            if (datas)
                input.find('input').attr('value', datas ? datas.pieces[i] : null)
            input.appendTo('#room-wp');
        }

        // adapt number & input name on each room cloned
        setTimeout(function () {
            var inputRoom = $('.item.type-room.cloned .input-room');
            $('.item.type-room.cloned').each(function () {
                let number = $(this).data('number');
                $(this)
                    .find('.number')
                    .text(number);
                $(this)
                    .find('.input-room')
                    .attr('name', 'room-' + number);
            });

            function getPieces() {
                pieces = []
                inputRoom.each(function (input) {
                    if (this.value)
                        pieces.push(parseInt(this.value))
                })
            }

            /* Event key Up */
            inputRoom.on('keyup', delay(function (e) {
                getPieces()
                $.post({
                    url: '/services/calc-price/' + le_slug,
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': csrf
                    },
                    data: JSON.stringify(
                        {pieces: pieces, type_client: parseInt(profile.val()) || 1}
                    )
                })
                    .done(data => {
                        total.text(data);
                    })
                    .fail(error => {
                        console.log(error)
                    })

            }, 500));

            submit.on('click', function (evt) {
                evt.preventDefault()
                $(this).hide()
                getPieces()
                if (pieces.length === 0) {
                    $(this).show()
                } else {

                    $.post({
                        url: '/shopping-cart/add-to-cart/' + le_slug,
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRF-TOKEN': csrf
                        },
                        data: JSON.stringify(
                            {pieces: pieces, type_client: parseInt(profile.val()) || 1}
                        )
                    })
                        .done(data => {
                            $(this).show()
                            window.open('/shopping-cart/panier', '_self')
                        })
                        .fail(error => {
                            console.log(error)
                        })
                }

            })
        }, 50);
    }

    if (pieces_datas)
        addPieces(null, pieces_datas)

    profile.on('changed.bs.select',function (a, index) {
        $('#error-profile').text(null)
    })
    select.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        addPieces(clickedIndex)
    });

}

// Initialisation
nav_mobile();
nav_sub_menu();
nav_size();
scroll_top();
service_form();
panier_form();
