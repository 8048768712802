// JS dependency
var vide = require('vide');

var $this = $('#home-video-wp');
var videoMp4 = $('#home-video-wp').data('mp4');
var videoWebm = $('#home-video-wp').data('webm');
var videoPoster = $('#home-video-wp').data('poster');

function videHeight() {
    var viewport = $(window).height();
    var header = $('#header').innerHeight();
    var height = $(window).width() * 9 /16;
    if (height > viewport - header) {
        height = viewport - header;
    }
    $this.height(height);
}

function videInit() {
    videHeight();
    $this.vide(
        {
            mp4: videoMp4,
            webm: videoWebm,
            poster: videoPoster
        },
        {
            posterType: 'png',
            resizing: true,
            autoplay: false,
            position: 'top center',
        }
    );
}

videInit();

$(window).on('resize', function () {
    videHeight();
});


$(document).ready(function () {
    if ($this.data('vide')) {
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
        if (!isSafari) {
            $this.data('vide').getVideoObject().play();
        }

    }
});
